import { makeStyles, useTheme } from "@material-ui/core/styles";
import Masonry from "react-masonry-css";

const useStyles = makeStyles((theme) => ({
  masonryGrid: {
    display: "flex",
    marginLeft: theme.spacing(-3),
    width: "inherit",
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0),
    },
  },
  masonryColumn: {
    paddingLeft: theme.spacing(3),
    backgroundClip: "padding-box",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
    },
  },
}));

const MasonryGrid = ({ children, xl, lg, md, sm, xs = 1 }) => {
  const classes = useStyles();
  const theme = useTheme();

  sm = sm ?? xs;
  md = md ?? sm;
  lg = lg ?? md;
  xl = xl ?? lg;

  const breakpointCols = {
    default: 2,
    [theme.breakpoints.values.xl]: xl,
    [theme.breakpoints.values.lg]: lg,
    [theme.breakpoints.values.md]: md,
    [theme.breakpoints.values.sm]: sm,
    [theme.breakpoints.values.xs]: xs,
  };

  return (
    <Masonry
      breakpointCols={breakpointCols}
      className={classes.masonryGrid}
      columnClassName={classes.masonryColumn}
    >
      {children}
    </Masonry>
  );
};

export default MasonryGrid;
