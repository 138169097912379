import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const GiantAvatar = styled(Box)(({ src, grey }) => ({
  filter: grey ? "grayscale(100%)" : "",
  height: 0,
  width: "70%",
  paddingBottom: "70%",
  backgroundImage: `url(${src})`,
  backgroundPosition: "top center",
  backgroundSize: "cover",
  borderRadius: "50%",
}));

export default GiantAvatar;
