import { Box } from "@material-ui/core";

// Components
import FullWidthImage from "./FullWidthImage";

const RoundedImage = ({ image, maxHeight = "100%", onClick, grey }) => {
  return (
    <Box
      maxHeight={maxHeight}
      mb={3}
      borderRadius="12px"
      onClick={onClick}
      clone
    >
      <FullWidthImage src={image} role="presentation" grey={grey} />
    </Box>
  );
};

export default RoundedImage;
