import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledBox = styled(Box)(({ theme }) => ({
  "& img": {
    height: theme.spacing(11),
  },
}));

const Logo = (props) => {
  return (
    <StyledBox>
      <img alt="Logo" src="/images/cso_logo.png" {...props} />
    </StyledBox>
  );
};

export default Logo;
