import { Box, Container } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";


const StyledBox = styled(Box)(({ theme, background, bgimage, gradient }) => {
  if (bgimage)
    return {
      backgroundColor:theme.palette[background],
      backgroundImage: `url('${bgimage}')`,
      background: gradient,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% auto",
      [theme.breakpoints.down("lg")]: {
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
    };
  return {
    background: theme.palette[background],
  };
});

const Section = ({ children, maxWidth, ...boxProps }) => (
  <StyledBox pt={5} pb={5} {...boxProps}>
    <Container maxWidth={maxWidth}>{children}</Container>
  </StyledBox>
);

export default Section;
