import Carousel from "react-material-ui-carousel";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";

const ChunkyCarousel = ({
  chunks = 1,
  children,
  spacing,
  ...carouselProps
}) => {
  const chunkyChildren = _.chunk(children, chunks).map((chunk) => (
    <Grid
      key={chunk.reduce((key, child) => child.key + key, "")}
      container
      spacing={spacing}
    >
      {chunk.map((child) => (
        <Grid key={child.key} item xs={12 / chunks}>
          {child}
        </Grid>
      ))}
    </Grid>
  ));

  return (
    <Carousel autoPlay={false} {...carouselProps}>
      {chunkyChildren}
    </Carousel>
  );
};

ChunkyCarousel.propTypes = {
  chunks: PropTypes.number,
  childen: PropTypes.array,
};

export default ChunkyCarousel;
