import { useState } from "react";
import { Box, Modal } from "@material-ui/core";

//Components
import RoundedImage from "./RoundedImage";

const ImageModal = ({ image, landing }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <RoundedImage
        image={image}
        onClick={() => setOpen(true)}
        grey={landing}
      />
      <Box justifyContent="center" alignItems="center" display="flex" clone>
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box borderRadius="14px" maxHeight="90%" clone>
            <img src={image} />
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default ImageModal;
